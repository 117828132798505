<template>
  <b-modal
    ref="modalUpdateNote"
    centered
    @show="show"
    @hidden="hide"
    body-class="p-4"
    v-model="isShowModal"
  >
    <template #modal-header>
      <div class="ft-black">Import</div>
    </template>
    <b-row>
      <b-col md="12" class="justify-content-start">
        <UploadFileV2
          textFloat="File"
          placeholder="Please choose file"
          format="excel"
          name="file"
          text="*Please upload only file .xlsx less than 10 MB"
          isRequired
          v-on:onFileChange="onFileChange"
          v-on:delete="deleteFile"
          :fileName="filename"
          v-model="form.excel_file"
          :v="$v.form.excel_file"
        />
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click="hide" class="btn-cancel"> Cancel </b-button></b-col
        >
        <b-col
          ><b-button
            class="btn-confirm"
            @click.prevent="importData"
            :disabled="isDisable"
          >
            Confirm
          </b-button></b-col
        >
      </b-row>
    </template>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="form"
      @changeEmail="(val) => (form.email = val)"
      @submit="sendForm"
    />
  </b-modal>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    UploadFileV2,
    ModalInputEmail,
  },
  validations: {
    form: {
      excel_file: { required },
    },
  },
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isShowModal: false,
      isDisable: true,
      form: {
        email: "",
        user_guid: "",
        file_name: "",
        excel_file: "",
      },
      filename: "",
    };
  },
  created() {
    this.form.email = this.$cookies.get("back_office_admin_email");
    this.form.user_guid = this.$cookies.get("back_office_admin_user_guid");
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
    onFileChange(file) {
      this.filename = file.name;
      this.form.file_name = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.form.excel_file = null;
      this.filename = null;
      this.isDisable = true;
    },
    importData() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$refs.ModalInputEmail.show();
    },
    async sendForm() {
      this.isDisable = true;
      await this.$store.dispatch("importReportRedeem", this.form);
      let data = this.$store.state.redeem.stateImportReportRedeem;
      if (data.result === 1) {
        this.$swal("Import Success!", {
          icon: "success",
        });
        window.location.href = `/redeem/detail/${this.id}`;
      } else {
        this.isDisable = false;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
</style>
