<template>
  <div>
    <b-modal
      v-model="modalShow"
      size="xl"
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >

      <div class="title-panel p-3">
        <b-row>
          <b-col md="6" class="text-left">
            Product List
          </b-col>
          <b-col md="6" class="text-right">
            <span>
              Latest Update : {{ new Date() | moment($formatDate) }}
              ({{new Date() | moment($formatTime)}})
            </span>
          </b-col>
        </b-row>
      </div>
      <div class="filter-panel p-3">
        <div class="category-panel" v-if="type == 1">
          <b-row>
            <b-col>
              <InputSelect
                title="Level Category 1"
                name="type"
                isRequired
                v-model="filter.ah1"
                v-bind:options="categoryList.category1"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 1)"
              />
            </b-col>
            <b-col>
              <InputSelect
                title="Level Category 2"
                name="type"
                isRequired
                v-model="filter.ah2"
                v-bind:options="categoryList.category2"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 2)"
              />
            </b-col>
            <b-col>
              <InputSelect
                title="Level Category 3"
                name="type"
                isRequired
                v-model="filter.ah3"
                v-bind:options="categoryList.category3"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 3)"
              />
            </b-col>
            <b-col>
              <InputSelect
                title="Level Category 4"
                name="type"
                isRequired
                v-model="filter.ah4"
                v-bind:options="categoryList.category4"
                valueField="ah_id"
                textField="ah_name"
                @onDataChange="selectCategory($event, 4)"
              />
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col md="6">
              <b-input-group>
                <b-form-input
                  type="search"
                  class="search-bar search-modal"
                  @keyup.enter="onSubmitFilter"
                  placeholder="Search Privilege Name, Promotion Code"
                  v-model="filter.search"
                >
                </b-form-input>
                <b-input-group-append is-text>
                  <b-iconstack font-scale="2" type="submit" @click="onSubmitFilter">
                    <b-icon
                      stacked
                      icon="search"
                      scale="0.5"
                      variant="grey"
                    ></b-icon>
                  </b-iconstack>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row class="pb-3 pl-3 pr-3">
        <b-col sm="12">
          <b-table
            responsive
            striped
            hover
            :fields="computedFieldsSelectProduct"
            :items="productList"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:head(id)="">
              <b-form-group class="cb-table">
                <b-form-checkbox
                  v-model="selectAllProduct"
                  @change="selectAllTable"
                ></b-form-checkbox>
              </b-form-group>
            </template>
            <template v-slot:cell(id)="data">
              <b-form-group class="cb-table">
                <b-form-checkbox
                  :value="data.item.id"
                  v-model="filter.productIdList"
                  @change="selectProductTable"
                ></b-form-checkbox>
              </b-form-group>
            </template>
            <template v-slot:cell(imge_url)="data">
              <div class="position-relative picture-text pic-table">
                <div
                  v-if="data.item.imge_url"
                  class="square-box b-contain pic-box"
                  v-bind:style="{
                    'background-image': 'url(' + data.item.imge_url + ')',
                  }"
                ></div>
                <div v-else>-</div>
              </div>
            </template>
            <template v-slot:cell(name)="data">
              <div class="text-left">
                <p class="m-0" v-if="data.item.name">
                  {{ data.item.name }}
                </p>
                <p
                  class="m-0 text-gray image-container"
                  v-html="data.item.description"
                  v-if="data.item.name"
                ></p>
                <p v-else class="m-0">-</p>
              </div>
            </template>
            <template v-slot:cell(barcode)="data">
              <p class="m-0" v-if="data.item.barcode">
                {{ data.item.barcode }}
              </p>
              <p v-else class="m-0">-</p>
            </template>

            <template v-slot:cell(price)="data">
              <p class="m-0 price-text" v-if="data.item.price">
                ฿ {{ data.item.price | numeral("0,0.00") }}
              </p>
              <p v-else class="m-0 price-text">-</p>
            </template>

            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col sm="4">
          <p class="mb-0 p-gray">
            Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
            {{
              filter.start + filter.length >= rows
                ? rows
                : filter.start + filter.length
            }}
            of {{ rows }} entires
          </p>
        </b-col>
        <b-col sm="8">
          <b-row class="pb-3">
            <b-col class="form-inline justify-content-end">
              <div class="d-sm-flex mr-2">
                <b-pagination
                  v-model="filter.pageNo"
                  :total-rows="rows"
                  :per-page="filter.length"
                  class="m-md-0 page-item"
                  @change="pagination"
                ></b-pagination>
              </div>
              <b-form-select
                v-model="filter.length"
                @change="hanndleChangePerpage"
                :options="pageOptions"
              ></b-form-select>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button @click.prevent="hide" class="border-btn">Cancel</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="submit-btn" @click.prevent="submitProduct">Save</b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputSelect,
  },
  props: {},
  data() {
    return {
      modalShow: false,
      isBusy: false,
      fieldsSelectProduct: [
        {
          key: "id",
          label: "",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "imge_url",
          label: "Photo",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "name",
          label: "Name / Detail",
          class: "text-nowrap",
          type: 1,
        },
        {
          key: "barcode",
          label: "SKU",
          class: "text-nowrap",
          type: 2,
        },
        {
          key: "price",
          label: "Price",
          class: "text-nowrap",
          type: 2,
        },
        {
          key: "discount",
          label: "Discount(%)",
          class: "text-nowrap",
          type: 4,
        },
        {
          key: "coupon",
          label: "Coupon Code",
          class: "text-nowrap",
          type: 3,
        },
        {
          key: "startDate",
          label: "Valid From",
          class: "text-nowrap",
          type: 3,
        },
        {
          key: "endDate",
          label: "Valid To",
          class: "text-nowrap",
          type: 3,
        },
      ],
      productList: [],
      allListId: [],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      rows: 0,
      filter: {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: null,
        ah2: null,
        ah3: null,
        ah4: null,
        productIdList: [],
      },
      categoryList: {
        category1: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category2: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category3: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category4: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
      },
      selectAllProduct: false,
      type: 1,
    };
  },
  computed: {
    computedFieldsSelectProduct() {
      if (this.type == 1) {
        return this.fieldsSelectProduct.filter(
          (field) => field.type != 3 && field.type != 4
        );
      } else if (this.type == 2) {
        return this.fieldsSelectProduct.filter(
          (field) => field.type != 2 && field.type != 4
        );
      } else {
        return this.fieldsSelectProduct.filter((field) => field.type != 2);
      }
    },
  },
  methods: {
    async show(type, select) {
      this.type = type;
      this.filter.productIdList = select;
      this.modalShow = true;
      await this.getProductList();
      this.selectAllProduct =
        this.productList.length == this.allListId.length ? true : false;
    },
    hide() {
      this.filter = {
        search: "",
        pageNo: 1,
        start: 0,
        length: 5,
        ah1: null,
        ah2: null,
        ah3: null,
        ah4: null,
        productIdList: [],
      };
      this.categoryList = {
        category1: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category2: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category3: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
        category4: [
          {
            ah_id: null,
            ah_name: "All",
          },
        ],
      };
      this.selectAllProduct = false;
      this.modalShow = false;
    },
    onSubmitFilter() {
      this.filter.pageNo = 1;
      this.filter.start = 0;
      this.filter.length = 5;
      this.getProductList();
    },
    submitProduct() {
      let product = [];
      this.filter.productIdList.forEach((e) => {
        product.push({ id: e, point: 0 });
      });
      let productSort = product.sort(function (a, b) {
        var x = b["id"];
        var y = a["id"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
      this.$emit("submitProduct", productSort);
      this.hide();
    },
    pagination(Page) {
      this.filter.pageNo = Page;
      this.getProductList();
    },
    hanndleChangePerpage(value) {
      this.filter.pageNo = 1;
      this.filter.length = value;
      this.getProductList();
    },
    async getProductList() {
      let item = [];
      let rows = 0;
      let allId = [];
      let data = {};
      this.isBusy = true;
      this.filter.start = (this.filter.pageNo - 1) * this.filter.length;
      if (this.type == 1) {
        await this.$store.dispatch("getListProduct", this.filter);
        data = this.$store.state.redeem.productList;
        item = data.detail.product_list;
        rows = data.detail.total_product;
        allId = data.detail.id_all;
      } else if (this.type == 2) {
      } else {
        let body = {
          id: 1,
          item_select: this.filter.productIdList,
          page: this.filter.pageNo,
          reference_id: null,
          search: "",
          show_only_select: 0,
          take: this.filter.length,
        };
        await this.$store.dispatch("ActionGetPrivilegeCondition", body);
        data = await this.$store.state.modulePrivilegeCode
          .stateGetPrivilegeCondition;
        item = data.detail.detail;
        rows = data.detail.total_count;
      }
      this.productList = item;
      this.rows = rows;
      this.allListId = allId;
      if (this.type == 1) {
        for (let i = 1; i <= 4; i++) {
          if (this.categoryList[`category${i}`].length == 1) {
            this.categoryList[`category${i}`] = data.detail[`list_ah${i}`]
              ? this.categoryList[`category${i}`].concat(
                  data.detail[`list_ah${i}`]
                )
              : this.categoryList[`category${i}`];
          }
        }
      }

      this.isBusy = false;
    },
    clearCategory(id, index) {
      this.filter.categories.forEach((element, cindex) => {
        if (cindex > index) {
          this.categoryList[`category${cindex}`] = [
            {
              value: 0,
              text: "All",
            },
          ];
          this.filter.categories[cindex] = 0;
        } else if (cindex == index) {
          this.filter.categories[cindex] = 0;
        }
      });
    },
    onCateGoryChange(id, index) {
      this.clearCategory(id, index);
      this.getCategoryList(id, index + 1);
      this.filter.categories[index - 1] = id;
      this.getProductList();
    },
    getCategoryList(id, index) {
      this.axios
        .get(`${this.$baseUrl}/productinventory/category/${id}`)
        .then((data) => {
          if (data.detail) {
            data.detail.category.forEach((l) => {
              this.categoryList[`category${index}`].push({
                value: l.categoryId,
                text: l.categoryName,
              });
            });
          } else {
            this.categoryList[`category${index}`] = [];
            this.categoryList[`category${index}`].push({
              value: 0,
              text: "All",
            });
            this.filter.categories[index] = 0;
          }
        });
    },
    selectAllTable(value) {
      if (value) {
        this.filter.productIdList = [];
        this.filter.productIdList = this.allListId;
      } else {
        this.filter.productIdList = [];
      }
    },
    selectProductTable(value) {
      console.log(this.filter.productIdList )
      console.log(value)
      this.selectAllProduct =
        this.allListId.length == value.length ? true : false;
    },
    selectCategory(value, num) {
      this.filter[`ah${num}`] = value;
      for (let i = 1; i <= 4; i++) {
        if (i > num) {
          this.categoryList[`category${i}`] = [
            {
              ah_id: null,
              ah_name: "All",
            },
          ];
          this.filter[`ah${i}`] = null;
        }
      }
      this.getProductList();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-search {
  color: #404040 !important;
  border: 1px solid #d8dbe0 !important;
  border-radius: 6px !important;
  padding: 9px 10px !important;
  height: 41px;
}
.btn-collapse {
  color: #333333;
}
.white-color {
  color: #ffffff;
}
.text-gray {
  color: #bcbcbc;
}
.btn-confirm-modal {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-modal {
  color: #fff;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
::v-deep .btn-select {
  height: 42.4px;
  color: #404040;
  background-color: white;
  border: 1px solid #d8dbe0;
  border-radius: 6px;
  padding: 9px 10px;
  cursor: pointer;
}
::v-deep .btn-select.custom-select-lg {
  height: 55px;
  font-size: 1rem;
}
::v-deep .btn-select:focus {
  border-color: #80c141;
}
::v-deep .btn-select > option {
  background-color: white;
  color: #16274a;
}
::v-deep .btn-select > option:checked {
  color: #16274a !important;
}
::v-deep .btn-select:focus {
  box-shadow: unset !important;
}
.btn-select-product {
  width: 150px;
}
.btn-search {
  background-color: var(--primary-color);
  color: #ffffff;
  width: 42.4px;
  height: 42.4px;
  padding: 6px 0px;
  margin-top: 10px !important;
  border-radius: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 1.42857;
}
.search-input {
  height: 42.4px;
  width: 98%;
  border: 1px solid #d8dbe0;
  border-radius: 7px;
}
.btn-table-select {
  height: 42.4px;
}
.pic-table {
  max-height: 65px !important;
}
.pic-box {
  padding-top: 23% !important;
}
.cb-table {
  position: relative;
  margin-left: 15px;
  padding-top: 15px;
}
::v-deep .modal-body {
  padding: 0 !important;
}
.image-container {
  width: 230px !important;
}
.text-header {
  color: #000;
  font-size: 18px;
}
.text-gray {
  color: #747474;
}
.search-modal {
  height:
   42.4px !important;
}
::v-deep .modal-footer{
  justify-content: space-between;
}
::v-deep img {
  width: 230px !important;
  height: 230px !important;
}
</style>
