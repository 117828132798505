<template>
  <div>
    <div class="title-panel">
      <b-row>
        <b-col md="6" class="text-left">General Information</b-col>
        <b-col md="6" class="text-right">
          <span v-if="id != 0">
            Latest Update : {{ new Date() | moment($formatDate) }} ({{
              new Date() | moment($formatTime)
            }})
          </span>
        </b-col>
      </b-row>
    </div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3">
        <div class="text-black">
          <b-row>
            <b-col sm="12">
              <InputText
                textFloat="Name"
                v-model="form.Name"
                isRequired
                placeholder="Name"
                type="text"
                :isValidate="v.Name.$error"
                :v="v.Name"
              />
            </b-col>
            <b-col sm="6">
              <label class="header-title font-weight-bold"
                >Number of Privileges <span class="text-error">*</span></label
              >
              <div>
                <b-form-group class="d-inline-block">
                  <b-form-radio-group
                    id="radio-total-redeem"
                    v-model="form.totalRedeemStatus"
                    :options="optionsTotalRedeem"
                    name="radio-total-redeem"
                    @change="changeTotalRedeem"
                  ></b-form-radio-group>
                </b-form-group>
                <div class="d-inline-block input-total-box">
                  <InputText
                    textFloat=""
                    v-model="form.Total_Redeemable"
                    class="ml-1 input-total d-inline-block"
                    placeholder="0"
                    type="number"
                    :disabled="!form.totalRedeemStatus"
                    :isValidate="v.Total_Redeemable.$error"
                    :v="v.Total_Redeemable"
                  />
                  <span class="mt-2 ml-3 position-absolute">Privilege</span>
                </div>
              </div>
            </b-col>
            <b-col sm="6">
              <label class="header-title font-weight-bold "
                >Terms and Conditions <span class="text-error">*</span></label
              >
              <div>
                <b-form-group class="d-inline-block">
                  <b-form-radio-group
                    id="radio-redeem-status"
                    v-model="form.Skip"
                    :options="optionsRedeemReceive"
                    name="radio-redeem-status"
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6">
              <label class="header-title font-weight-bold mt-2"
                >Conditions For Receiving The Goods
                <span class="text-error">*</span></label
              >
              <div>
                <b-form-group class="d-inline-block">
                  <b-form-radio-group
                    id="radio-pickup-status"
                    v-model="form.pick_Up_Type_Id"
                    value-field="value"
                    text-field="text"
                    :options="optionRedeemPickup"
                    name="radio-pickup-status"
                  ></b-form-radio-group>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-container>
    <div class="title-panel mt-3">
      <b-row>
        <b-col md="6" class="text-left">Conditions</b-col>
        <b-col md="6" class="text-right">
          <span v-if="id != 0">
            Latest Update : {{ new Date() | moment($formatDate) }} ({{
              new Date() | moment($formatTime)
            }})
          </span>
        </b-col>
      </b-row>
    </div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3 pb-3">
        <div class="text-black">
          <div class="">
            <label class="header-title font-weight-bold"
              >Redemption Type <span class="text-error">*</span></label
            >
            <div>
              <b-form-group class="d-inline-block">
                <b-form-radio-group
                  id="radio-redeem-type"
                  v-model="form.redeemType"
                  :options="optionsRedeemType"
                  name="radio-redeem-type"
                  @change="changeRedeemType"
                ></b-form-radio-group>
              </b-form-group>
            </div>
          </div>
          <div>
            <p>
              <strong class="text-header">Product List</strong>
            </p>
            <b-button class="btn-select-product" @click="openProductModal"
              ><div class="px-1">
                <span class="float-left font-weight-bold">Select Item</span
                ><span class="float-right"
                  ><b-icon icon="chevron-right"></b-icon
                ></span></div
            ></b-button>
            <b-row>
              <b-col sm="12" class="mt-3">
                <b-table
                  :fields="computedFields"
                  :items="productListSelect"
                  :busy="isBusy"
                  show-empty
                  empty-text="No matching records found"
                  responsive
                  striped
                  hover
                >
                  <template v-slot:cell(imageUrl)="data">
                    <div class="position-relative picture-text pic-table">
                      <div
                        v-if="data.item.imageUrl"
                        class="square-box b-contain image"
                        v-bind:style="{
                          'background-image': 'url(' + data.item.imageUrl + ')',
                        }"
                      ></div>
                      <div v-else>-</div>
                    </div>
                  </template>
                  <template v-slot:cell(name)="data">
                    <div class="text-left">
                      <p class="m-0" v-if="data.item.name">
                        {{ data.item.name }}
                      </p>
                      <p
                        class="m-0"
                        v-if="data.item.name"
                        v-html="data.item.description"
                      ></p>
                      <p v-else class="m-0">-</p>
                    </div>
                  </template>
                  <template v-slot:cell(barcode)="data">
                    <p class="m-0" v-if="data.item.barcode">
                      {{ data.item.barcode }}
                    </p>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(coupon)="data">
                    <p class="m-0" v-if="data.item.coupon">
                      {{ data.item.coupon }}
                    </p>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(startDate)="data">
                    <div v-if="data.item.startDate">
                      <span>{{
                        new Date(data.item.startDate) | moment($formatDate)
                      }}</span>
                      <p>
                        ({{
                          new Date(data.item.startDate) | moment($formatTime)
                        }})
                      </p>
                    </div>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(endDate)="data">
                    <div v-if="data.item.endDate">
                      <span>{{
                        new Date(data.item.endDate) | moment($formatDate)
                      }}</span>
                      <p>
                        ({{ new Date(data.item.endDate) | moment($formatTime) }})
                      </p>
                    </div>
                    <p v-else class="m-0">-</p>
                  </template>
                  <template v-slot:cell(price)="data">
                    <p class="m-0 price-text" v-if="data.item.price">
                      ฿ {{ data.item.price | numeral("0,0.00") }}
                    </p>
                    <p v-else class="m-0 price-text">-</p>
                  </template>
                  <template v-slot:cell(point)="data">
                    <InputText
                      textFloat=""
                      v-model="data.item.point"
                      placeholder="0"
                      type="number"
                    />
                  </template>
                  <template v-slot:cell(delete)="data">
                    <b-button
                      variant="link"
                      class="pb-3"
                      v-if="form.redeemType != 1"
                    >
                      <router-link
                        :to="'/product/detail/' + data.item.id"
                        target="_blank"
                      >
                        <u>Detail</u>
                      </router-link>
                    </b-button>
                    <b-button
                      variant="icon"
                      class="px-0 pb-4"
                      @click="deleteProduct(data.item)"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </template>
                  <template v-slot:table-busy>
                    <div class="text-center text-black my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong class="ml-2">Loading...</strong>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" class="form-inline justify-content-start">
                <p class="mb-0 p-gray">
                  Showing {{ rows > 0 ? filter.start + 1 : 0 }} to
                  {{
                    filter.start + filter.length >= rows
                      ? rows
                      : filter.start + filter.length
                  }}
                  of {{ rows }} entires
                </p>
              </b-col>
              <b-col cols="12" md="6" class="form-inline justify-content-end">
                <div>
                  <b-pagination
                    v-model="filter.page"
                    :total-rows="rows"
                    :per-page="filter.length"
                    @change="pagination"
                    first-text="First"
                    prev-text="Prev"
                    next-text="Next"
                    last-text="Last"
                    class="m-md-0"
                  ></b-pagination>
                </div>
                <div class="ml-2">
                  <b-form-select
                    v-model="filter.length"
                    @change="handlePerpage"
                    :options="pageOptions"
                  ></b-form-select>
                </div>
              </b-col>
              <b-col sm="12" v-if="v.items.selectProduct.$error">
                <p class="text-danger">
                  Please enter points more than 0 on products.
                </p>
              </b-col>
            </b-row>
          </div>
          <hr />
          <div class="mt-3">
            <b-row>
              <b-col sm="6">
                <div>
                  <label class="font-weight-bold">
                    Date of Use (Start) <span class="text-danger">*</span>
                  </label>
                  <div class="input-container">
                    <datetime
                      type="datetime"
                      :input-style="styleDatetime"
                      v-model="form.Valid_From"
                      placeholder="DD/MM/YYYY (HH:MM)"
                      format="dd/MM/yyyy (HH:mm)"
                      value-zone="Asia/Bangkok"
                      @close="changeStartDate"
                      ref="transferDateStart"
                      :isValidate="v.Valid_From.$error"
                      :v="v.Valid_From"
                    >
                    </datetime>
                    <div
                      :class="
                        'icon-container text-right ' + id == 0
                          ? ''
                          : 'cursor-default'
                      "
                      @click="
                        $refs.transferDateStart.isOpen = id == 0 ? true : false
                      "
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="
                          id == 0
                            ? 'pointer color-primary'
                            : 'cursor-default color-primary'
                        "
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col sm="6">
                <div>
                  <label class="font-weight-bold">
                    Date of Use (End) <span class="text-danger">*</span>
                  </label>
                  <div class="input-container">
                    <datetime
                      type="datetime"
                      :input-style="styleDatetime"
                      v-model="form.Valid_To"
                      placeholder="DD/MM/YYYY (HH:MM)"
                      format="dd/MM/yyyy (HH:mm)"
                      value-zone="Asia/Bangkok"
                      ref="transferDateEnd"
                      :min-datetime="form.Valid_From"
                      :isValidate="v.Valid_To.$error"
                      :v="v.Valid_To"
                    >
                    </datetime>
                    <div
                      class="icon-container text-right"
                      @click="$refs.transferDateEnd.isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="
                          id == 0
                            ? 'pointer color-primary'
                            : 'cursor-default color-primary'
                        "
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { BIcon } from "bootstrap-vue";
export default {
  name: "RedeemFormDetail",
  components: {
    InputText,
    BIcon,
  },
  props: {
    id: {
      type: Number,
      isRequired: true,
    },
    form: {
      type: Object,
      isRequired: true,
    },
    v: {
      type: Object,
      isRequired: true,
    },
  },
  data() {
    return {
      isBusy: false,
      filter: {
        redeemId: parseInt(this.id),
        branchId: 0,
        redeemOrderStatus: 0,
        search: "",
        onlyInRedeem: 1,
        page: 1,
        length: 5,
        start: 0,
        selectProductList: [],
        DeleteProductList: [],
      },
      rows: 0,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      fields: [
        {
          key: "imageUrl",
          label: "Photo",
          class: "w-50px text-nowrap",
          type: 1,
        },
        {
          key: "name",
          label: "Product Name",
          class: "w-100px text-nowrap",
          type: 1,
        },
        {
          key: "barcode",
          label: "SKU",
          class: "w-50px text-nowrap",
          type: 2,
        },
        {
          key: "discount",
          label: "Discount (%)",
          class: "w-50px text-nowrap",
          type: 4,
        },
        {
          key: "coupon",
          label: "Coupon Code",
          class: "w-50px text-nowrap",
          type: 3,
        },
        {
          key: "startDate",
          label: "Valid From",
          class: "w-50px text-nowrap",
          type: 3,
        },
        {
          key: "endDate",
          label: "Valid To",
          class: "w-50px text-nowrap",
          type: 3,
        },
        {
          key: "price",
          label: "Price",
          class: "w-50px text-nowrap",
          type: 2,
        },
        {
          key: "point",
          label: "Point",
          class: "w-50px text-nowrap",
          type: 1,
        },
        {
          key: "delete",
          label: "",
          class: "w-50px text-nowrap",
          type: 1,
        },
      ],
      productListSelect: [],
      optionsTotalRedeem: [
        { text: "Unlimited Number", value: false },
        { text: "Limited Number", value: true },
      ],
      optionsRedeemReceive: [
        { text: "Get It Now", value: 1 },
        { text: "Prepare", value: 0 },
      ],
      optionsRedeemType: [
        { text: "Single Product", value: 1 },
        // { text: "Privillege", value: 2 },
        { text: "Coupon", value: 3 },
      ],
      optionRedeemPickup: [
        { text: "Pick Up at the Branch", value: 1 },
        { text: "Pick Up at Home", value: 2 },
      ],
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },
  computed: {
    computedFields() {
      if (this.form.redeemType == 1) {
        return this.fields.filter(
          (field) => field.type != 3 && field.type != 4
        );
      } else if (this.form.redeemType == 2) {
        return this.fields.filter(
          (field) => field.type != 2 && field.type != 4
        );
      } else {
        return this.fields.filter((field) => field.type != 2);
      }
    },
    widthManage() {
      return this.form.redeemType == 1 ? "5%" : "10%";
    },
  },
  created: async function () {},
  methods: {
    changeTotalRedeem() {
      if (!this.form.totalRedeemStatus) {
        this.form.Total_Redeemable = 0;
      }
    },
    openProductModal() {
      this.$emit("openProductModal", this.form.redeemType);
    },
    async pagination(page) {
      this.filter.page = page;
      await this.setProductPoint();
      this.getProductSelectList(1);
    },
    async handlePerpage(value) {
      this.filter.page = 1;
      this.filter.length = value;
      await this.setProductPoint();
      this.getProductSelectList(1);
    },
    changeStartDate() {
      if (this.form.Valid_From && this.form.Valid_To) {
        this.form.Valid_To =
          this.form.Valid_From >= this.form.Valid_To ? "" : this.form.Valid_To;
      }
    },
    changeRedeemType() {
      this.form.items.selectProduct = [];
      this.filter.selectProductList = [];
      this.productListSelect = [];
      this.pagination(1);
    },
    async getProductSelectList(flag) {
      this.isBusy = true;
      this.filter.start = (this.filter.page - 1) * this.filter.length;
      this.filter.redeemType = this.form.redeemType;
      await this.setProductPoint();
      await this.$store.dispatch("getListProductSelect", this.filter);
      const data = this.$store.state.redeem.productSelectList;
      if (data.result == 1) {
        this.productListSelect = await data.detail.data;
        await this.productListSelect.forEach((e, index) => {
          e.index = index;
        });
        this.rows = data.detail.productListTotal;
        if (flag == 0) {
          this.form.items.selectProduct = this.productListSelect;
          this.filter.selectProductList = this.productListSelect;
        }
      } else {
        this.productListSelect = [];
        this.rows = 0;
      }
      this.isBusy = false;
    },
    async deleteProduct(data) {

      await this.filter.DeleteProductList.push(
        this.productListSelect[data.index].id
      );
      this.form.items.DeleteProduct.push(data.id);

      this.filter.selectProductList = this.filter.selectProductList.filter(
        (item) => item.id != data.id
      );
      await this.form.items.selectProduct.splice(data.index, 1);
      await this.productListSelect.splice(data.index, 1);
      this.productListSelect.forEach((e, index) => {
        e.index = index;
      });

      this.setProductPoint();
    },
    setProductPoint() {
      this.productListSelect.forEach((e) => {
        this.filter.selectProductList.forEach((select) => {
          if (e.id == select.id) {
            select.point = parseInt(e.point);
          }
        });
      });
      this.form.items.selectProduct = this.filter.selectProductList;
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
label {
  font-size: 16px;
}
.text-header {
  color: #000;
  font-size: 18px;
}
.text-black {
  color: #000;
}
.input-total-box {
  position: absolute;
  margin-top: -10px;
}
.input-total {
  width: 80px;
}
.btn-select-product {
  width: 150px;
  height: 42.4px;
  color: #7160c4;
  background-color: white;
  border: 1px solid #7160c4;
}
.input-container {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  align-items: center;
  justify-content: space-between;
}
.icon-container {
  flex: 1 1 auto;
  cursor: text;
}
.status-box {
  width: 90px;
}
.status-box label {
  font-size: 18px;
}
.status-switch {
  position: absolute;
  margin-top: -18px;
}
.pic-table {
  max-height: 65px !important;
}
::v-deep .vdatetime-popup {
  width: 270px;
}
::v-deep img {
  width: 230px !important;
  height: 230px !important;
}
</style>
